import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
	return (
		<>
			<p>Portfolio</p>
		</>
	);
}

export default Portfolio;
