import React from 'react';
import './Footer.css';
import { Button } from '../../components';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div className='footer-container'>
			{/* <section className='footer-subscription'>
				<p className='footer-subscription-heading'>
					Join the Adventure newsletter to receive our best vacation deals
				</p>
				<p className='footer-subscription-text'>
					You can unsubscribe at any time.
				</p>
				<div className='input-areas'>
					<form>
						<input
							className='footer-input'
							name='email'
							type='email'
							placeholder='Your Email'
						/>
						<Button buttonStyle='btn--outline'>Subscribe</Button>
					</form>
				</div>
			</section> */}
			<section class='disclaimer'>
				<div class='disclaimer-wrap'>
					<div className='footer-link-wrapper'>
						<Button
							className='btns'
							buttonStyle='btn--outline'
							buttonSize='btn--large'
							to='/disclaimer'>
							Disclaimer
						</Button>
					</div>
					<div className='footer-link-wrapper'>
						<small class='website-rights'>
							Copyright © 2020 SARV CAPITAL SERVICES LLP - All Rights Reserved.
						</small>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Footer;
