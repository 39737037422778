import React from 'react';
import { HeroSection } from '../../containers';

const Home = () => {
	return (
		<>
			<HeroSection />
		</>
	);
}

export default Home;
