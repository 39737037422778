import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Footer, Navbar } from '../../containers'
import { Home, Portfolio, Strategy, Disclaimer } from '../../pages';

const Layout = () => {
	return (
		<>
			<Navbar />
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/portfolio' exact component={Portfolio} />
				<Route path='/strategy' exact component={Strategy} />
				<Route path='/Disclaimer' exact component={Disclaimer} />
			</Switch>
			<Footer />
		</>
	)

}

export default Layout