import React from 'react';
import { Button } from '../../components';

import './HeroSection.css';

function HeroSection() {
	return (
		<div className='hero-container'>
			<video src='/videos/video-3.mp4' autoPlay loop muted />
			<h1>Integrity & Performance</h1>
			<p>build enduring institutions</p>
		</div>
	);
}

export default HeroSection;
