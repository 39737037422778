import React from 'react';
import './Strategy.module.scss';


const Strategy = () => {
	return (
		<>
			<p>Strategy</p>
		</>
	);
}

export default Strategy;
