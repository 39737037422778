import React from 'react';
import './Disclaimer.css';

const Disclaimer = () => {
	return (
		<div className='disclaimer-container'>
			<h2>DISCLAIMER</h2>
			<div className='disclaimer-content'>
				<p>
					Any offering will be made only pursuant to the private placement
					memorandum of the Fund/Scheme and other relevant subscription and
					scheme documents. Any past performance inferred on this website is not
					a guarantee of future results. It is not intended as an offer or
					solicitation for the purchase and sale of any financial instrument.
					Any action that you may take on the basis of the information contained
					herein is your responsibility; and SARV CAPITAL SERVICES LLP will not
					be liable in any manner for the consequences of any such action. We
					have exercised due diligence in checking the correctness and
					authenticity of the information contained herein some of which may be
					forecasts and/or forward looking statements, but do not represent that
					it is accurate or complete. In no event will SARV CAPITAL SERVICES
					LLP, the fund , trustees of the fund and/or any of its Partners or
					officers or employees shall be liable or in any way responsible for
					any loss or damage direct or indirect, special, incidental, or
					consequential damages, losses or expenses that may arise to any person
					from the information contained on this website. SARV CAPITAL SERVICES
					LLP may have interests or positions, financial or otherwise in the
					transactions mentioned on this website. All visitors of this website
					before dealing and/or transacting in any of the products referred to
					in the website make their own investigation, seek appropriate advice
					including advice from their tax consultant. The name of the
					fund/scheme does not in any manner indicate either the quality of the
					products or their future prospects and returns.
				</p>
				<br />
				<p>
					Investments in securities are subject to market risks. There are no
					assurances or guarantees that the objective of any of the scheme will
					be achieved. The investments may not be suited to all categories of
					investors. The value of the portfolio can go up and down depending on
					various market factors. The performance of the scheme may be adversely
					affected by performance of individual companies, changes in market
					conditions, micro and macro factors and forces affecting capital
					markets in particular like interest rate risk, credit risk, liquidity
					risk and reinvestment risk. Investment in the scheme can be illiquid
					and scheme may engage in investment practices which may involve
					volatility of returns and significant risk of loss, including the
					potential for loss of the principal invested. Potential risks may
					include, but are not limited to: no investor control; losses due to
					leveraging, short-selling and hedging transactions; lack of liquidity;
					absence of information regarding valuations and pricing; complex tax
					structures and delays in tax reporting; and limited government
					regulation. The views on this website are generally those of SARV
					CAPITAL SERVICES LLP and are subject to change without notice, and
					SARV CAPITAL SERVICES LLP has no obligation to update its view or the
					information on this website. Individual investor return will vary
					depending on timing of investment, eligibility of new issue income and
					fee structure.
				</p>
			</div>
		</div>
	);
};

export default Disclaimer;
